import { createGlobalStyle } from "styled-components"
import ArchivoRegularWOFF2 from "../fonts/archivo-regular-subset.woff2"
import ArchivoBlackWOFF2 from "../fonts/archivo-black-subset.woff2"

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Archivo Regular";
    src: url(${ArchivoRegularWOFF2});
    font-weight: 400;
  }
  @font-face {
    font-family: "Archivo Black";
    src: url(${ArchivoBlackWOFF2});
    font-weight: 900;
  }
  * {
    word-wrap: normal;
    margin: 0;
    font-weight: normal;
    padding: 0;
    text-decoration: none;
    color: whitesmoke;
  }
  h1 {
    font-family: "Archivo Black", sans-serif;
    padding-top: 6rem;
    font-size: 5.76em;

    @media screen and (max-width: 90rem) {
      font-size: 3.5em;
      padding-top: 3rem;
    }
    @media screen and (max-width: 37rem) {
      font-size: 2.5em;
      padding-top: 1rem;
    }
  }
  
  h2 {
    font-size: 2.5em;
    line-height: 1.5em;

    @media screen and (max-width: 90rem) {
      font-size: 1.7em;
    }
    @media screen and (max-width: 37rem) {
      font-size: 1.4em;
    }
  }
  
  h3 {
    font-size: 5em;
    line-height: 1em;
    font-family: "Archivo Black", sans-serif;

    @media screen and (max-width: 90rem) {
      font-size: 2.82em;
    }
    @media screen and (max-width: 37rem) {
      font-size: 1.8em;
    }
  }
  
  h4 {
    padding-top: 2rem;
    font-size: 1.9em;
    line-height: 1.3em;
    letter-spacing: 0.04em;
    font-family: "Archivo Black", sans-serif;
    @media screen and (max-width: 90rem) {
      font-size: 1.3em;
      padding-top: 1rem;
    }
    @media screen and (max-width: 37rem) {
      font-size: 1.1em;
    }
  }
  
  h5 {
    font-size: 1.5em;
    font-family: "Archivo Black", sans-serif;
    line-height: 1.3em;
    letter-spacing: 0.045em;
    @media screen and (max-width: 90rem) {
      font-size: 1.1em;
    }
    @media screen and (max-width: 37rem) {
      font-size: 1em;
    }
  }
  h6 {
    font-size: 1.5em;
    font-family: "Archivo Black", sans-serif;
    line-height: 1.3em;
    letter-spacing: 0.045em;
    @media screen and (max-width: 90rem) {
      font-size: 1.1em;
    }
    @media screen and (max-width: 37rem) {
      font-size: 1em;
    }
  }
  html, body {
    min-height: 100%;
    overflow-x: hidden;
    font-family: "Archivo Regular", sans-serif;
  }

  body {
    background: ${props => props.theme.colors.background};
  }

  p {
    padding-top: 0.5em;
    line-height: 1.5em;
    font-size: 1.3em;
    letter-spacing: 0.09em;
    @media screen and (max-width: 37rem) {
      font-size: 1em;
    }
  }
`