import styled from "styled-components"

export const Grid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 10vw repeat(12, 5.15vw) 10vw;
  grid-template-rows: 6rem 4rem auto;
  gap: 0 1.4vw;
  padding-bottom: 2rem;

  @media screen and (max-width: 90rem) {
    grid-template-columns: 4vw repeat(6, 13vw) 4vw;
    grid-template-rows: 5rem 4rem auto;
    grid-gap: 0 2vw;
    padding-bottom: 2rem;
  }

  @media screen and (max-width: 37rem) {
    grid-template-columns: 2vw repeat(6, 14.25vw) 2vw;
    grid-template-rows: 4.5rem 6rem auto;
    grid-gap: 0 1.5vw;
    padding-bottom: 1rem;
  }
`