import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const FooterWrapper = styled.footer`
  grid-column: 2 / span 12;
  grid-row: 40;
  min-height: 2rem;
  padding: 1.6rem 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-weight: lighter;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 5;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 5;
  }
`
const PrimaryFooterText = styled.p`
  color: ${props => props.theme.colors.secundaryDark};
  @media screen and (max-width: 37rem) {
    font-size: 0.9em;
  }
`

export const Footer = () => {
  return (
    <FooterWrapper>
      <PrimaryFooterText>
        created 2021 by <a href="mailto:">Selina Pali</a>
      </PrimaryFooterText>
      <PrimaryFooterText>
        <Link to="/imprint">Imprint</Link>
      </PrimaryFooterText>
    </FooterWrapper>
  )
}

export default Footer
