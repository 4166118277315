import React from "react"
import { Navbar } from "./Navbar"
import { Footer } from "./Footer"
import Scroll from "./Scroll"
import { Grid } from "./Grid"
import { GlobalStyles } from "../theme/GlobalStyles"

const Layout = ({ children }) => {
  return (
    <Grid>
      <GlobalStyles />
      <Navbar />
      <Scroll showBelow={350} />
      {children}
      <Footer />
    </Grid>
  )
}
export default Layout
