import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ArrowUp from "../icons/arrow-up.svg"

const StyledButton = styled.button`
  z-index: 15;
  position: fixed;
  bottom: 0;
  background-color: ${props => props.theme.colors.cards};
  right: 2.5%;
  height: 10.8em;
  width: 5.3em;
  outline: none;
  border-color: ${props => props.theme.colors.cards};
  border: none;
  border-radius: 52px 0 0 0;
  cursor: pointer;
  :hover  {
    background-color: #111112;
  }
  @media screen and (max-width: 90rem) {
    right: 1.8%;
    height: 8.8em;
    width: 4.3em;
    background-color: ${props => props.theme.colors.darkHighlight};
    border-color: ${props => props.theme.colors.darkHighlight};
    border-radius: 42px 0 0 0;
  }
  @media screen and (max-width: 37rem) {
    right: 2vw;
    height: 7.5em;
    width: 3.3em;
    border-radius: 32px 0 0 0;
  }
`
const Arrow = styled.div`
  margin: -40px auto;
  width: 1.6em;
  @media screen and (max-width: 90rem) {
    margin: -10px auto 0 auto;
  }
  @media screen and (max-width: 37rem) {
    margin: -10px auto 0 auto;
  }
`

const Scroll = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }
  return (
    <div>
      {show && (
        <StyledButton onClick={handleClick}>
          <Arrow>
            <img src={ArrowUp} alt={"Arrow up"} />
          </Arrow>
        </StyledButton>
      )}
    </div>
  )
}

export default Scroll
